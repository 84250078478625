


























































































































































































































import { defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import {
	addDays,
	addMinutes,
	endOfDay,
	intlFormat,
	isBefore,
	isPast,
	startOfDay,
	subDays,
} from "date-fns";
import { mapStores } from "pinia";
import { mapActions } from "vuex";
import { coachApi, reserveerApi } from "@/lib/backend";
import type { IomodelsFitnessAfspraakExtern } from "@/lib/backend/reserveer.api";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { getCoachName } from "@/lib/getCoachName";
import { queryOptions_coaches } from "@/lib/query/functions/coaches";
import { logger } from "@/logger";
import { useLocationStore } from "@/pinia/location";
import { usePopupStore } from "@/pinia/popup";
import { capitalize } from "@/utils/capitalize";
import { getDayMonth, getDayMonthYear, getTimeOfDay, getWeekDay } from "@/utils/date";
import { getMemberImage } from "@/utils/images";

type Status = "Alle" | "Openstaand" | "Geweest" | "Niet geweest";

type Data = {
	loading: boolean;
	date: Date;
	afspraken: IomodelsFitnessAfspraakExtern[];
	vestiging: "all" | LocationSlug;
	getAppointmentsInterval?: ReturnType<typeof setInterval>;
	statussen: Status[];
	status: Status;
};

type FitnessAfspraakWithIsTemporaryMember = IomodelsFitnessAfspraakExtern & {
	aanwezigheid?: IomodelsFitnessAfspraakExtern["aanwezigheid"] & { isTemporaryMember: boolean };
};

export default defineComponent({
	name: "PfgAfspraken",
	setup() {
		const { data: coaches } = useQuery(queryOptions_coaches());

		return { coaches, getCoachName };
	},
	data(): Data {
		return {
			loading: false,
			date: new Date(),
			afspraken: [],
			vestiging: "all",
			getAppointmentsInterval: undefined,
			statussen: ["Alle", "Openstaand", "Geweest", "Niet geweest"],
			status: "Alle",
		};
	},
	computed: {
		...mapStores(usePopupStore, useLocationStore),
		filteredAfspraken(): FitnessAfspraakWithIsTemporaryMember[] {
			const afspraken = this.afspraken.filter(
				(afspraak) => afspraak.status !== "geannuleerd",
			) as FitnessAfspraakWithIsTemporaryMember[];

			switch (this.status) {
				case "Alle": {
					return afspraken;
				}
				case "Openstaand": {
					return afspraken.filter((afspraak) => !afspraak.ingediend);
				}
				default:
				case "Geweest": {
					return afspraken.filter((afspraak) => afspraak.aanwezigheid?.status === "geweest");
				}
				case "Niet geweest": {
					return afspraken.filter((afspraak) => afspraak.aanwezigheid?.status === "niet_geweest");
				}
			}
		},
	},
	watch: {
		async date() {
			this.loading = true;

			await this.getAppointments();

			this.loading = false;
		},
		async vestiging() {
			this.loading = true;

			await this.getAppointments();

			this.loading = false;
		},
	},
	async created() {
		await this.check();
	},
	async beforeDestroy() {
		if (this.getAppointmentsInterval) clearInterval(this.getAppointmentsInterval);
	},
	methods: {
		...mapActions("modal", { openModal: "openModal" }),
		async check() {
			this.loading = true;

			if (this.$router.currentRoute.query["inplannen"]) this.openAfspraakModal();

			await this.locationStore.init();

			this.vestiging = this.locationStore.location?.slug ?? "all";

			this.loading = false;
		},
		getAppointmentText({ aanwezigheid }: IomodelsFitnessAfspraakExtern): string {
			const values = Object.entries(aanwezigheid ?? {})
				.filter(([key]) => Object.keys(APPOINTMENT_MAP).includes(key))
				.filter(([, value]) => value === true)
				.map(([key]) => APPOINTMENT_MAP[key].shortText);

			if (values.length === 0) return "";

			// eslint-disable-next-line
			// @ts-ignore - ListFormat is not correctly typed within compiler TS
			return new Intl.ListFormat("nl", {
				style: "long",
				type: "conjunction",
			}).format(values);
		},
		async getAppointments() {
			const response = await reserveerApi.fitnessafspraken.afsprakenVoorRange({
				startDatum: startOfDay(this.date).toISOString(),
				eindDatum: endOfDay(this.date).toISOString(),
				aanwezigheidsStatus: ["afgemeld", "aangemeld", "geweest", "niet_geweest"],
				...(this.vestiging !== "all" && {
					location: {
						id: {
							equals: this.locationStore.locationBySlug(this.vestiging)?.id,
						},
					},
				}),
			});

			switch (response.status) {
				case 200: {
					this.afspraken = response.data;

					if (this.getAppointmentsInterval) clearInterval(this.getAppointmentsInterval);

					if (this.afspraken.length) {
						this.getAppointmentsInterval = setInterval(() => {
							this.getAppointments();
						}, 60000);
					}

					break;
				}

				default: {
					throw response;
				}
			}
		},
		hasAfspraakPassed(afspraak: IomodelsFitnessAfspraakExtern): boolean {
			return !afspraak.ingediend && isPast(addMinutes(new Date(afspraak.datum), 15));
		},
		isAfspraakDone(afspraak: IomodelsFitnessAfspraakExtern): boolean {
			return afspraak.ingediend && afspraak.status === "afgelopen";
		},
		isAfspraakInFuture(afspraak: IomodelsFitnessAfspraakExtern): boolean {
			return isBefore(new Date(), new Date(afspraak.datum));
		},
		async done(afspraak: IomodelsFitnessAfspraakExtern, status: "geweest" | "niet_geweest") {
			try {
				const aanwezigheid = afspraak.aanwezigheid;

				if (!aanwezigheid) {
					throw new Error("Geen afspraak aanwezigheid");
				}

				const response = await coachApi.api.appointmentsDoneCreate(afspraak.id, {
					...aanwezigheid,
					lidNaam: aanwezigheid.lidNaam ?? "",
					status,
				});

				switch (response.status) {
					case 200: {
						await Promise.all([this.openFollowUpPopup(afspraak), this.getAppointments()]);
						return;
					}

					default: {
						throw response;
					}
				}
			} catch (error) {
				logger.error("Failed to mark appointment as done", error);
				this.popupStore.showError(
					`Er ging iets mis bij het maken van de afspraak.<br/>Probeer het later nog eens.`,
				);
			}
		},
		donePopup(afspraak: IomodelsFitnessAfspraakExtern) {
			const date = new Date(afspraak.datum);

			return this.popupStore.open({
				title: "Afspraak afronden",
				body: `Is <b>${afspraak.aanwezigheid?.lidNaam}</b> langs geweest op <b>${getDayMonthYear(
					date,
				)}</b> om <b>${getTimeOfDay(date)}</b> en heb je alle wensen besproken?`,
				buttons: {
					cancel: "Annuleren",
					confirm: "Ja, afspraak afronden",
				},
				callback: async () => {
					await this.done(afspraak, "geweest");
				},
			});
		},
		notDonePopup(afspraak: IomodelsFitnessAfspraakExtern) {
			const date = new Date(afspraak.datum);

			return this.popupStore.open({
				title: "Afspraak aanpassen",
				body: `<b>${
					afspraak.aanwezigheid?.lidNaam
				}</b> is niet langs geweest op <b>${getDayMonthYear(
					date,
				)}</b> om <b>${getTimeOfDay(date)}</b>.`,
				buttons: {
					cancel: "Annuleren",
					confirm: "Ja, lid is niet geweest",
				},
				callback: async () => {
					await this.done(afspraak, "niet_geweest");
				},
			});
		},
		cancelPopup(afspraak: IomodelsFitnessAfspraakExtern) {
			const date = new Date(afspraak.datum);

			return this.popupStore.open({
				title: "Afspraak annuleren",
				body: `Weet je zeker dat je de afspraak wilt annuleren voor <b>${
					afspraak.aanwezigheid?.lidNaam
				}</b> op <b>${getDayMonthYear(date)}</b> om <b>${getTimeOfDay(date)}</b>?`,
				message: {
					title: `Laat <b>${afspraak.aanwezigheid?.lidNaam}</b> weten waarom:`,
					text: "",
				},
				buttons: {
					cancel: "Sluiten",
					confirm: "Afspraak annuleren",
				},
				callback: async (reden) => {
					await this.cancelAfspraak(afspraak, reden);
				},
			});
		},
		async cancelAfspraak(afspraak: IomodelsFitnessAfspraakExtern, reden?: string) {
			const opmerkingen = `Reden van annulering: ${reden ? reden.trim() : "Geen reden opgegeven"}${
				afspraak.aanwezigheid?.opmerkingen
					? `

      ${afspraak.aanwezigheid.opmerkingen}`
					: ""
			}`;

			try {
				const aanwezigheid = afspraak.aanwezigheid;

				if (!aanwezigheid) {
					throw new Error("Geen afspraak aanwezigheid");
				}

				const response = await coachApi.api.fitnessafsprakenAanwezigheidCreate(afspraak.id, {
					...aanwezigheid,
					lidNaam: aanwezigheid.lidNaam ?? "",
					opmerkingen,
					status: "afgemeld",
					isTemporaryMember:
						(aanwezigheid as { isTemporaryMember?: boolean })?.isTemporaryMember ?? false,
				});

				switch (response.status) {
					case 200: {
						await Promise.all([this.openFollowUpPopup(afspraak), this.getAppointments()]);
						return;
					}

					default: {
						throw response;
					}
				}
			} catch (error) {
				logger.error("Failed to cancel appointment", error);
				this.popupStore.showError(
					`Er ging iets mis bij het annuleren van de afspraak.<br/>Probeer het later nog eens.`,
				);
			}
		},
		async openFollowUpPopup(afspraak: IomodelsFitnessAfspraakExtern) {
			const lidId = afspraak.aanwezigheid?.lidId;

			if (!lidId) {
				logger.error("No `lidId` for `openFollowUpPopup` in `afspraak`", afspraak);
				return;
			}

			const upcomingAppointments = await reserveerApi.fitnessafspraken
				.getAfsprakenVoorLid(lidId, {
					datum_gte: new Date().toISOString(),
					status: "aankomend",
					orderby_datum: "asc",
				})
				.then((response) => response.data);

			logger.trace("Upcoming appointments", upcomingAppointments);

			return this.popupStore.open({
				title: "Vervolgafspraak inplannen",
				body:
					upcomingAppointments.length > 0
						? `<p>Maak mogelijk een vervolgafspraak voor de sporter. Deze heeft al wel de volgende afspraken gepland staan:</p>
						<div class="flex flex-col gap-4 mt-2 -mb-2">${upcomingAppointments
							.map(
								(appointment) =>
									`<div class="flex flex-col gap-2 rounded-2xl bg-gray-50 p-4 text-xs">
										<strong class="truncate text-sm font-bold text-black">${intlFormat(new Date(appointment.datum), { day: "numeric", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit" }, { locale: "nl" })}</strong>
										${
											appointment.aanwezigheid
												? Object.entries(appointment.aanwezigheid)
														.filter(([key]) => Object.keys(APPOINTMENT_MAP).includes(key))
														.filter(([, value]) => value === true)
														.map<(typeof APPOINTMENT_MAP)[number] & { key: string }>(([key]) => ({
															key,
															...APPOINTMENT_MAP[key],
														}))
														.map(
															(action) => `<div class="flex items-center gap-1">
														<span width="1rem" height="1rem" class="svg-container grid h-6 w-6 place-content-center"><svg width="1rem" height="1rem" role="img" class="block fill-current *:fill-current"><use xlink:href="#icon_${action.icon}"></use></svg></span>
														<span class="text-xs">${action.text}</span>
														</div>`,
														)
														.join("")
												: ""
										}
									</div>`,
							)
							.join("")}</div>`
						: "Maak meteen een vervolgafspraak om de sporter aan de gang te houden.",
				buttons: {
					cancel: "Annuleren",
					confirm: "Afspraak maken",
				},
				callback: () =>
					this.$router.push({
						name: "Member appointment create",
						params: { id: String(afspraak.aanwezigheid?.lidId) },
					}),
			});
		},
		prevDay() {
			this.date = subDays(this.date, 1);
		},
		nextDay() {
			this.date = addDays(this.date, 1);
		},
		openAfspraakModal() {
			this.openModal({
				name: "afspraak-inplannen",
			});
		},
		openOpmerking(afspraak: IomodelsFitnessAfspraakExtern) {
			this.popupStore.open({
				title: "Opmerking",
				body: afspraak.aanwezigheid?.opmerkingen || undefined,
				buttons: {
					cancel: "Sluiten",
				},
			});
		},
		getMemberImage,
		capitalize,
		getDayMonthYear,
		getDayMonth,
		getTimeOfDay,
		getWeekDay,
	},
});
